// extracted by mini-css-extract-plugin
export var UserExp = "styles-module--UserExp--BFIL2";
export var UserExpHeading = "styles-module--UserExpHeading--upPog";
export var UserExpParagraph = "styles-module--UserExpParagraph--drS6y";
export var UserExp_heading = "styles-module--UserExp_heading--FBcfZ";
export var UserExpblock = "styles-module--UserExpblock--TIsHO";
export var UserExppic = "styles-module--UserExppic--KnR5G";
export var UserExpstatic = "styles-module--UserExpstatic--HVmcA";
export var UserExptool = "styles-module--UserExptool--wZL-k";
export var mainExprow = "styles-module--mainExprow--14h7i";
export var mainUserExp = "styles-module--mainUserExp--29uiE";
export var mainUserExp1 = "styles-module--mainUserExp1--MCUqE";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--jtdE-";