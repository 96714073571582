import AOS from "aos"
import React from "react"
import "aos/dist/aos.css"
import MobileSvg from "assets/icons/mobile"
import { Col, Row } from "react-bootstrap"
import { ServicesPage } from "../../../constants/index"
import * as styles from "./styles.module.scss"

const Mobilepage = () => {
	const height = 250
	const mobile = ServicesPage.mobile
	const mobileTechniques = ServicesPage.mTechniques

	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<>
			<div className={styles.mainmobilerow} id="MobDev">
				<Row className={styles.mainmobile1}>
					{mobile.map((mobileServe, index) => {
						return (
							<Col key={index}>
								<h2 className={styles.mob_heading}>{mobileServe.title}</h2>
							</Col>
						)
					})}
				</Row>
				<Row xs={1} md={2} className={styles.mainmobile}>
					<Col
						sm={12}
						md={3}
						className={styles.mobpic}
						data-aos="flip-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="800"
						data-aos-mirror="true"
					>
						<MobileSvg />
					</Col>
					{mobile.map((mobileServe, index) => {
						return (
							<Col key={index} className={styles.mobparagraph} sm={12} md={7}>
								<p>{mobileServe.detail}</p>
							</Col>
						)
					})}
				</Row>
				<Row className={styles.mainmobile1}>
					<div className={styles.techTools}>
						<p className={styles.mobtool} md={4}>
							Tools and Technique
						</p>
					</div>
				</Row>
				<Row className={styles.mainmobile} xs={1} sm={3} md={3} lg={4}>
					{mobileTechniques.map((mobileTechnique, index) => {
						return (
							<div key={index} className={styles.mobblock}>
								{mobileTechnique.icon}
								<p className={styles.mobheading}>{mobileTechnique.title}</p>
							</div>
						)
					})}
				</Row>
			</div>
		</>
	)
}
export default Mobilepage
