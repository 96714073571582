// extracted by mini-css-extract-plugin
export var mainmobile = "styles-module--mainmobile--bMLXF";
export var mainmobile1 = "styles-module--mainmobile1--6v0uP";
export var mainmobilerow = "styles-module--mainmobilerow--8YY6z";
export var mob_heading = "styles-module--mob_heading--K03Jv";
export var mobblock = "styles-module--mobblock--j8P40";
export var mobheading = "styles-module--mobheading--zI8FK";
export var mobparagraph = "styles-module--mobparagraph--Y9by5";
export var mobpic = "styles-module--mobpic--AviFE";
export var mobstatic = "styles-module--mobstatic--3ugNu";
export var mobsvg1 = "styles-module--mobsvg1--AiLwy";
export var mobtool = "styles-module--mobtool--UEzHj";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--Cn4O9";