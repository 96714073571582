import React from "react"
import * as styles from "./styles.module.scss"
import { Row, Col } from "react-bootstrap"
import WebSvg from "assets/icons/web"
import AOS from "aos"
import "aos/dist/aos.css"
import { ServicesPage } from "../../../constants/index"

const Webpage = () => {
	const web = ServicesPage.web
	const webTechniques = ServicesPage.wTechniques

	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<>
			<div className={` ${styles.mainWeb} mt-5 `} id="WebDev">
				<Row className={styles.mainRow1}>
					{web.map((webservice, index) => {
						return (
							<Col key={index}>
								<h1 className={styles.web_heading}>{webservice.title}</h1>
							</Col>
						)
					})}
				</Row>
				<Row xs={1} md={2} className={styles.mainRow}>
					<Col
						sm={12}
						md={3}
						className={styles.Webpic}
						data-aos="flip-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="800"
						data-aos-mirror="true"
					>
						<WebSvg />
					</Col>
					{web.map((webservice, index) => {
						return (
							<Col key={index} className={styles.Webparagraph} sm={12} md={7}>
								<p className={` mx-0 ${styles.webser}`} style={{ marginTop: "15px", paddingRight: "15px" }}>
									{webservice.detail}
									<br />
									{webservice.detail1}
									<br />
									{webservice.detail2}
									<br />
									{webservice.detail3}
								</p>
							</Col>
						)
					})}
				</Row>
				<Row className={styles.mainRow}>
					<div className={styles.techTools}>
						<p className={styles.webtool} md={4}>
							Tools and Technique
						</p>
					</div>
				</Row>
				<Row className={`${styles.cardsmainRow}`} xs={1} sm={2} md={3} lg={4}>
					{webTechniques.map((technique, index) => {
						return (
							<div key={index} className={`${styles.webblock} "container"`}>
								{technique.icon}
								<p className={styles.webheading}>{technique.title}</p>
							</div>
						)
					})}
				</Row>
			</div>
		</>
	)
}
export default Webpage
