import React from "react"
import * as styles from "./styles.module.scss"
import { Row, Col } from "react-bootstrap"
import AOS from "aos"
import "aos/dist/aos.css"
import { ServicesPage } from "../../../constants/index"

const IOTpage = () => {
	const height = 300
	const IOT = ServicesPage.iot
	const IOTTools = ServicesPage.iotTools
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<>
			<div className={styles.mainIotRow} id="EcomDev">
				<Row className={styles.mainEcommerce1}>
					{IOT.map((iotService, index) => {
						return (
							<Col key={index}>
								<h2 className={styles.Ecom_heading}>{iotService.title}</h2>
							</Col>
						)
					})}
					{/* {IOT.map((iotService, index) => {
						return (
							<Col key={index}>
								<h2 className={styles.Ecom_heading}>{iotService.title}</h2>
							</Col>
						)
					})} */}
				</Row>
				<Row xs={1} md={2} className={styles.mainEcommerce1}>
					<Col
						sm={12}
						md={3}
						className={styles.Ecompic}
						data-aos="flip-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="800"
						data-aos-mirror="true"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="180.333" height="180.333" version="1" viewBox="0 0 64 64">
							<path
								fill="#fff"
								d="M212 593c-18-9-44-35-58-59-18-30-34-45-55-49-38-9-87-58-91-93-10-83 41-152 112-152 39 0 40-1 40-33 0-20-8-39-20-50-35-30-16-87 30-87s65 57 30 87c-12 11-20 30-20 50v33h130v-53c0-40-5-57-20-70-35-30-16-87 30-87s65 57 30 87c-15 13-20 30-20 70v53h130v-33c0-20-8-39-20-50-35-30-16-87 30-87s65 57 30 87c-12 11-20 30-20 50 0 33 0 33 51 33 28 0 59 4 69 10 38 20 45 103 11 124-6 4-19 27-30 51-22 49-69 85-113 85-19 0-31 9-46 33-36 62-138 86-210 50zm155-23c18-10 39-33 48-50 13-24 22-30 49-30 42 0 91-38 101-77 3-16 16-38 27-50 25-27 27-72 2-90-10-7-39-13-66-13-40 0-48 3-48 19 0 10 7 24 15 31 20 16 16 49-7 64-37 23-77-36-43-64 8-7 15-21 15-31 0-17-8-19-65-19-65 0-65 0-65 29 0 16 7 34 15 41 34 28-6 87-42 64-24-15-28-48-8-64 8-7 15-25 15-41 0-29 0-29-65-29-57 0-65 2-65 19 0 10 7 24 15 31 20 16 16 49-8 64-36 23-76-36-42-64 8-7 15-21 15-31 0-37-93-19-119 23-16 25-17 88-2 116 13 24 60 52 89 52 12 0 24 14 36 41 10 22 32 49 50 60 41 25 111 25 153-1zm-31-201c10-17-13-36-27-22-12 12-4 33 11 33 5 0 12-5 16-11zm-150-20c10-17-13-36-27-22-12 12-4 33 11 33 5 0 12-5 16-11zm300 0c10-17-13-36-27-22-12 12-4 33 11 33 5 0 12-5 16-11zM195 120c0-18-6-26-23-28-24-4-38 18-28 44 3 9 15 14 28 12 17-2 23-10 23-28zm300 0c0-18-6-26-23-28-13-2-25 3-28 12-10 26 4 48 28 44 17-2 23-10 23-28zM345 80c0-18-6-26-23-28-24-4-38 18-28 44 3 9 15 14 28 12 17-2 23-10 23-28z"
								transform="matrix(.1 0 0 -.1 0 64)"
							></path>
						</svg>
					</Col>
					{IOT.map((iotService, index) => {
						return (
							<Col key={index} className={styles.Ecomparagraph} sm={12} md={7}>
								<p>{iotService.detail}</p>
							</Col>
						)
					})}
				</Row>
				<Row className={styles.mainEcommerce1}>
					<div className={styles.techTools}>
						<p className={styles.Ecomtool} md={4}>
							Tools and Technique
						</p>
					</div>
				</Row>
				<Row className={styles.mainEcommerce} xs={1} sm={3} md={3} lg={4}>
					{IOTTools.map((iotTool, index) => {
						return (
							<div key={index} className={styles.Ecomblock}>
								{iotTool.icon}
								<p className={styles.Ecomheading}>{iotTool.title}</p>
							</div>
						)
					})}
				</Row>
			</div>
		</>
	)
}
export default IOTpage
