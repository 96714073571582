// extracted by mini-css-extract-plugin
export var Ecom_heading = "styles-module--Ecom_heading--A7AYw";
export var Ecomblock = "styles-module--Ecomblock--tN50Y";
export var Ecomheading = "styles-module--Ecomheading--sYAbm";
export var Ecomparagraph = "styles-module--Ecomparagraph--Dgr6M";
export var Ecompic = "styles-module--Ecompic---TI9U";
export var Ecomtool = "styles-module--Ecomtool--n4NzN";
export var mainCommercerow = "styles-module--mainCommercerow--6fgdH";
export var mainEcommerce = "styles-module--mainEcommerce--T05OB";
export var mainEcommerce1 = "styles-module--mainEcommerce1--O60vi";
export var mobheading = "styles-module--mobheading--yrwrl";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--QcVcl";