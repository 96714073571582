import React from "react"
import Webpage from "components/pages/web"
import Mobilepage from "components/pages/Mobile"
import Ecommercepage from "components/pages/Ecommerce"
import Gamepage from "components/pages/Game"
import UserExppage from "components/pages/UserExperience"
import Formpage from "components/pages/Form"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import { Helmet } from "react-helmet"
import "./styles.scss"
import IOTpage from "components/pages/IOT"

const Servicespage = () => {
	return (
		<>
			<Helmet>
				<title>Refstal Solutions | Services </title>
			</Helmet>
			<HeaderTwo />
			<Webpage />
			<Mobilepage />
			<IOTpage />
			<Ecommercepage />
			<Gamepage />
			<UserExppage />
			<Formpage />
			<Footer />
		</>
	)
}

export default Servicespage
