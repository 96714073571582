// extracted by mini-css-extract-plugin
export var Ecom_heading = "styles-module--Ecom_heading--7UOMz";
export var Ecomblock = "styles-module--Ecomblock--xVfxu";
export var Ecomheading = "styles-module--Ecomheading--+jZdl";
export var Ecomparagraph = "styles-module--Ecomparagraph--D-mGc";
export var Ecompic = "styles-module--Ecompic--RKdpI";
export var Ecomtool = "styles-module--Ecomtool--gxtKr";
export var mainEcommerce = "styles-module--mainEcommerce--RY09X";
export var mainEcommerce1 = "styles-module--mainEcommerce1--+2LH0";
export var mainIotRow = "styles-module--mainIotRow--r3rsa";
export var mobheading = "styles-module--mobheading--sJ5SJ";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--c0aG8";