import React from "react"
export default function MobileSvg(props) {
	return (
		<svg
			transform="scale(1.1)"
			xmlns="http://www.w3.org/2000/svg"
			// width="150px"
			// height="150px"
			version="1"
			// viewBox="0 0 810 810"
			viewBox="110 150 610 610"
			className="svgmobile"
		>
			<defs>
				<clipPath id="d50df054db">
					<path d="M215.855 215.855h378v378h-378zm0 0"></path>
				</clipPath>
			</defs>
			<g clipPath="url(#d50df054db)">
				<path
					fill="#fff"
					d="M455.254 581.254c-69.473 0-126-56.527-126-126s56.527-126 126-126 126 56.527 126 126-56.527 126-126 126zm-226.8-56.7V247.356c0-10.433 8.468-18.902 18.901-18.902h25.2v6.3c0 10.435 8.465 18.903 18.898 18.903h50.402c10.434 0 18.899-8.469 18.899-18.902v-6.3h25.2c10.437 0 18.901 8.468 18.901 18.901v78.825c-51.558 20.203-88.199 70.43-88.199 129.074 0 22.668 5.512 44.078 15.207 63h-53.008c-3.468 0-6.3 2.832-6.3 6.3 0 3.47 2.832 6.301 6.3 6.301h60.317c2.855 4.38 5.93 8.59 9.254 12.598h-101.07c-10.434 0-18.903-8.465-18.903-18.898zm56.702-296.1h63v6.3c0 3.473-2.832 6.3-6.3 6.3h-50.403c-3.469 0-6.297-2.827-6.297-6.3zm170.098 88.202c-13.117 0-25.766 1.867-37.8 5.29v-74.59c0-17.376-14.126-31.5-31.5-31.5H247.354c-17.375 0-31.5 14.124-31.5 31.5v277.199c0 17.375 14.125 31.5 31.5 31.5h112.907c24.832 23.402 58.25 37.8 94.992 37.8 76.437 0 138.601-62.164 138.601-138.601 0-76.438-62.164-138.598-138.601-138.598"
				></path>
			</g>
			<path
				fill="#fff"
				d="M409.898 413.691c-2.09-2.808-6.05-3.375-8.808-1.28l-50.399 37.8a6.283 6.283 0 00-2.535 5.043c0 1.996.934 3.84 2.535 5.047l50.399 37.8a6.301 6.301 0 003.765 1.254 6.26 6.26 0 005.043-2.535 6.266 6.266 0 00-1.277-8.808l-43.656-32.758L408.62 422.5c2.805-2.094 3.371-6.031 1.277-8.809M500.633 413.691c-2.113 2.778-1.524 6.715 1.258 8.809l43.68 32.754-43.68 32.758c-2.782 2.09-3.371 6.027-1.258 8.808 1.23 1.672 3.129 2.535 5.047 2.535a6.353 6.353 0 003.765-1.253l50.399-37.801c1.574-1.207 2.511-3.051 2.511-5.047 0-1.992-.937-3.84-2.511-5.043l-50.399-37.8c-2.78-2.067-6.742-1.528-8.812 1.28M476.148 392.574a6.288 6.288 0 00-7.972 3.988l-37.801 113.399c-1.082 3.297.688 6.867 3.988 7.973a6.324 6.324 0 007.973-3.984l37.8-113.403c1.083-3.297-.69-6.863-3.988-7.973"
			></path>
		</svg>
	)
}
